body {
    font-family: Arial, Helvetica, sans-serif;
    color: #eee;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    width: 0 !important;
    background: transparent !important;
}

.App {
    text-align: center;
    background-color: #304C85;
    min-height: 100vh;
    display: flex;
}

div.main-container {
    width: 100%;
}

.header-navigation {
    display: flex;
    justify-content: center;
}

.header-navigation h1 {
    width: 240px ;
}

.pointer:hover {
    cursor: pointer;
}

.hover-grow {
    transition: transform 0.1s ease-in-out;
}

.hover-grow:hover {
    transform: scale(1.15);
}

.chart-tooltip {
    border-radius: 3px;
    background-color: #eee;
    padding: 4px;
    margin-right: 10px;
    font-size: 12px;
    color: #222;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.chart-container {
    border-radius: 5px;
    background-color: #283B6B;
    padding: 4px;
    margin: 0 16px 0 16px;
    height: 28em;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

.keynumber-container {
    display: flex;
    margin: 16px;
    gap: 16px;
}

.keynumber-cell {
    flex: 1;
    background-color: #283B6B;
    border-radius: 5px;
    padding: 32px 8px 32px 8px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.keynumber-cell span {
    font-size: 40px;
    margin-right: 12px;
}

span.keynumber-cell-subtext {
    font-size: 16px;
    margin-top: 8px;
}

.svg-image {
    width: 100px;
    height: auto;
}

@media (max-width: 768px) {
    .chart-container {
        margin: 0 8px 0 8px;
        height: 22em;
    }

    .keynumber-container {
        margin: 8px;
        gap: 8px;
    }

    .keynumber-cell span {
        font-size: 20px;
        margin-right: 4px;
    }

    span.keynumber-cell-subtext {
        font-size: 12px;
        margin-top: 6px;
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.buttonLink {
    display: inline-block;
    padding: 5px 10px;
    margin: 10px;
    background-color: #856930;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonLink:hover {
    background-color: #b09149;
}

/* Skeleton animation */

.skeleton {
    background-color: #304C85;
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, transparent 0%, #283B6B 50%, transparent 100%);
    animation: loading 750ms infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-0%);
    }
    100% {
        transform: translateX(100%);
    }
}